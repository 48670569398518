import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { ExternalAuthUrlBuilder } from './ExternalAuthUrlBuilder';
import { SoloNativeRTCChannel } from '@/core/rtcChannel/SoloNativeRTCChannel';

export class ExternalAuthUrlDirector {
  buildWarpDriveUrl(baseUrl: string) {
    const builder = new ExternalAuthUrlBuilder(baseUrl);
    builder.setRedirectUri('warpdrive');
    builder.setLocale();
    builder.setCsrfToken();
    return builder.getUrl();
  }

  buildSamlUrl(baseUrl: string) {
    const IdPUrl = new URL(baseUrl);

    // In Solo SAML we don't need to worry about altering the RelayState
    if (SoloNativeRTCChannel.isChannelAvailable()) {
      return IdPUrl;
    }
    // TODO: remove the fallback and add a check here to make sure we validate this search param.
    const relayStateName = IdPUrl.searchParams.get('RelayStateName');
    if (!relayStateName?.trim()) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    let relayStateUrl = IdPUrl.searchParams.get(relayStateName);
    if (!relayStateUrl) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        WsErrorCodes.ERROR_INVALID_INPUT
      );
    }
    // According to https://docs.aws.amazon.com/workspaces/latest/adminguide/setting-up-saml.html?icmpid=docs_console_unmapped#enable-integration-saml
    // The default relayStateName is "RelayState".
    // When the customer is using ADFS, it requires the following step to get the valid relayStateUrl because the same keyword, RelayState, exists in the user access URL
    const regex = /=.*?=(.*)$/;
    const extractedUrl = relayStateUrl.match(regex)?.[1];

    if (extractedUrl) {
      relayStateUrl = extractedUrl;
    }

    // build relay state url
    const relayStateBuilder = new ExternalAuthUrlBuilder(relayStateUrl);
    relayStateBuilder.setRedirectUri('saml');

    // build IdP url
    const IdPBuilder = new ExternalAuthUrlBuilder(baseUrl);
    IdPBuilder.setRelayStateUrl(
      relayStateName,
      relayStateBuilder.getUrl().toString()
    );

    return IdPBuilder.getUrl();
  }

  buildIdcUrl(baseUrl: string) {
    return new ExternalAuthUrlBuilder(baseUrl).getUrl();
  }
}
